<template>
    <div class = "col-right">
       <a-spin :spinning = "spinning">
            <a-card>
            发票管理/帐单明细/帐单周期{{billDetail.billStartTime}}至{{billDetail.billEndTime}}
        </a-card>
           <a-card>
               <div class="text-center">
                   <a-row type="flex">
                   <a-col :flex="4">
                       <div>帐单金额</div>
                       <div class="num">{{billDetail.billAmount}}</div>
                   </a-col>
                   <a-col :flex="4">
                       <div>订单商品金额(元)</div>
                       <div class="num">{{billDetail.goodsAmount}}</div>
                   </a-col>
                   <a-col :flex="4">
                       <div>退款单商品金额(元)</div>
                       <div class="num">{{billDetail.refundAmount}}</div>
                   </a-col>
                   <a-col :flex="4">
                       <div>运费金额(元)</div>
                       <div class="num">{{billDetail.expressFee}}</div>
                   </a-col>
                   <a-col :flex="4">
                       <div>退运费金额(元)</div>
                       <div class="num">{{billDetail.refundExpressFee}}</div>
                   </a-col>
               </a-row>
               </div>
           </a-card>
           <a-card>
               <a-tabs default-active-key="1">
                   <a-tab-pane key="1" tab="商品金额明细">
                       <a-table :columns="goodsColumns" :data-source="goodsList" :pagination="false">
                           <a slot="order_no" slot-scope="text, record" @click="$jumpTo('/buyer-detail/order/'+record.order_id)">
                               <div>{{record.order_no}}</div>
                               <div>{{record.outOrderSn}}</div>
                           </a>
                           <div slot="confirmtime" slot-scope="text, record">
                               {{record.refund_state == 0 ? record.confirmtime : record.refund_at}}
                           </div>
                           <div slot="income" slot-scope="text, record">
                               {{record.refund_state == 0 ? '收入' : '支出'}}
                           </div>
                       </a-table>
                       <div class="pagination" v-if="goodsTotal > 0">
                           <a-pagination v-model="goodsQueryParams.page" :pageSize="goodsQueryParams.pageSize" :total="goodsTotal" show-less-items @change="changeGoodsPage" />
                       </div>

                   </a-tab-pane>
                   <a-tab-pane key="2" tab="运费金额明细">
                       <a-table :columns="expressFeeColumns" :data-source="expressFeeList" :pagination="false">
                           <a slot="order_no" slot-scope="text, record" @click="$jumpTo('/buyer-detail/order/'+record.order_id)">
                               <div>{{record.order_no}}</div>
                           </a>
                           <div slot="confirmtime" slot-scope="text, record">
                               {{record.refund_state == 0 ? record.confirmtime : record.refund_at}}
                           </div>
                           <div slot="income" slot-scope="text, record">
                               {{record.refund_state == 0 ? '收入' : '支出'}}
                           </div>
                       </a-table>
                       <div class="pagination" v-if="expressFeeTotal > 0">
                           <a-pagination v-model="expressFeeQueryParams.page" :pageSize="expressFeeQueryParams.pageSize" :total="expressFeeTotal" show-less-items @change="changeExpressFeePage" />
                       </div>
                   </a-tab-pane>
               </a-tabs>
           </a-card>
       </a-spin>
    </div>
</template>

<script>
    import service from "../../../utils/request";

    export default {
        name: "bill",
        data() {
            return {
                spinning: false,
                billDetail: {},
                goodsColumns:[
                    {title:'业务单号|外部订单号', key:'order_no', dataIndex:'order_no', scopedSlots: { customRender: 'order_no' }},
                    {title:'入帐时间', key:'confirmtime', dataIndex:'confirmtime', scopedSlots: { customRender: 'confirmtime' }},
                    {title:'商品名称', key:'goods_title', dataIndex:'goods_title', scopedSlots: { customRender: 'goods_title' }},
                    {title:'规格名称', key:'goods_spec', dataIndex:'goods_spec', scopedSlots: { customRender: 'goods_spec' }},
                    {title:'收支类型', key:'income', dataIndex:'income', scopedSlots: { customRender: 'income' }},
                    {title:'含税单价(元)', key:'price_market', dataIndex:'price_market', scopedSlots: { customRender: 'price_market' }},
                    {title:'数量', key:'number_goods', dataIndex:'number_goods', scopedSlots: { customRender: 'number_goods' }},
                    {title:'含税金额(元)', key:'price_real', dataIndex:'price_real', scopedSlots: { customRender: 'price_real' }},
                ],
                expressFeeColumns:[
                    {title:'业务单号', key:'order_no', dataIndex:'order_no', scopedSlots: { customRender: 'order_no' }},
                    {title:'入帐时间', key:'confirmtime', dataIndex:'confirmtime', scopedSlots: { customRender: 'confirmtime' }},
                    {title:'收支类型', key:'income', dataIndex:'income', scopedSlots: { customRender: 'income' }},
                    {title:'含税金额(元)', key:'price_express', dataIndex:'price_express', scopedSlots: { customRender: 'price_express' }},
                ],
                goodsList:[],
                expressFeeList:[],
                goodsQueryParams:{
                    page:1,
                    pageSize: 10
                },
                expressFeeQueryParams:{
                    page:1,
                    pageSize: 10
                },
                goodsTotal: 0,
                expressFeeTotal: 0,
            }
        },
        created() {
            this.goodsQueryParams.invoiceBillId = this.$route.params.id;
            this.expressFeeQueryParams.invoiceBillId = this.$route.params.id;
            this.getBillDetail();
            this.getGoodsList();
            this.getExpressFeeList();
        },
        methods: {
            getBillDetail() {
                service.post(service.uri.invoice.getBillDetail, {id: this.$route.params.id}).then(res => {
                    if (res.code == 200) {
                        this.billDetail = res.data;

                    }
                });
            },

            getGoodsList(){
                service.post(service.uri.invoice.getGoodsList, this.goodsQueryParams).then(res => {
                    if (res.code == 200) {
                        this.goodsList = res.data.list;
                        this.goodsTotal = res.data.total;
                    }
                });
            },
            getExpressFeeList(){
                service.post(service.uri.invoice.getExpressFeeList, this.expressFeeQueryParams).then(res => {
                    if (res.code == 200) {
                        this.expressFeeList = res.data.list;
                        this.expressFeeTotal = res.data.total;
                    }
                });
            },
            changeGoodsPage(e){
                this.goodsQueryParams.page = e;
            },
            changeExpressFeePage(e){
                this.expressFeeQueryParams.page = e;
            },
        },
    }
</script>

<style scoped>

</style>